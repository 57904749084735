var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.groupedNews)?_c('v-container',{staticClass:"mt-12 mx-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-skeleton-loader',{attrs:{"type":"button"}})],1),_vm._l((20),function(n){return _c('v-col',{key:n,attrs:{"cols":"7"}},[_c('v-skeleton-loader',{staticClass:"loader",attrs:{"type":"list-item"}})],1)})],2)],1):_c('v-container',{ref:"newsContainer",staticClass:"news-container",attrs:{"fluid":""},on:{"scroll":_vm.scrollListener}},[_c('vue-topprogress',{ref:"topProgress",attrs:{"color":"#FF4444"}}),_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 filter-button",attrs:{"fab":"","dark":"","small":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}])},[_c('v-list',[_c('h5',{staticClass:"ml-4"},[_vm._v(" mostrar/esconder fontes ")]),_vm._l((_vm.sources),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_c('v-checkbox',{attrs:{"color":"primary","off-icon":"mdi-eye","on-icon":"mdi-eye-off","value":item.source},on:{"change":function($event){return _vm.filterSource(item.source)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(_vm.clearSource(item.source))+" ")]),_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(item.count)+" ")])]},proxy:true}],null,true)})],1)],1)})],2)],1),_vm._l((_vm.groupedNews),function(group){return _c('v-row',{key:group.topic,staticClass:"d-flex align-start"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(group.pool.length)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.groupKeywords(group.pool)))])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((group.pool),function(post){return _c('v-slide-item',{key:post.id},[_c('v-card',{staticClass:"ma-4",attrs:{"height":"200","width":"200"}},[_c('v-card-text',{staticStyle:{"height":"160px"}},[_c('a',{staticClass:"news-link card",attrs:{"href":post.href,"target":"_blank"}},[_vm._v(" "+_vm._s(post.title)+" ")]),_vm._v(" "+_vm._s(post.image)+" ")]),_c('v-card-actions',{staticStyle:{"height":"40px"}},[(_vm.loggedUserId)?_c('v-icon',{staticClass:"mr-2",class:{ 'toggling-fav': _vm.savingNews === post.id },attrs:{"ripple":false,"color":"yellow"},on:{"click":function($event){return _vm.toggleFav(post.id)}}},[_vm._v(" "+_vm._s(_vm.favorites.indexOf(("" + (post.id))) >= 0 ? 'mdi-star' : 'mdi-star-outline')+" ")]):_vm._e(),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s((" | " + (_vm.clearSource(post.source))))+" ")]),_c('v-chip',{style:({
                position: 'absolute',
                top: 0,
                right: 0
                }),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(parseFloat(post.rating).toFixed(3))+" ")])],1)],1)],1)}),1)],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-chip',[_vm._v(" "+_vm._s(_vm.ungroupedNews.length)+" ungrouped ")])],1),_vm._l((_vm.ungroupedNews),function(post){return _c('v-col',{key:post.id,attrs:{"cols":"12"}},[_c('p',{staticClass:"d-flex align-center"},[(_vm.loggedUserId)?_c('v-icon',{staticClass:"mr-2",class:{ 'toggling-fav': _vm.savingNews === post.id },attrs:{"ripple":false,"color":"yellow"},on:{"click":function($event){return _vm.toggleFav(post.id)}}},[_vm._v(" "+_vm._s(_vm.favorites.indexOf(("" + (post.id))) >= 0 ? 'mdi-star' : 'mdi-star-outline')+" ")]):_vm._e(),_c('a',{staticClass:"news-link",attrs:{"href":post.href,"target":"_blank"}},[_vm._v(" "+_vm._s(post.title)+" "),_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s((" | " + (_vm.clearSource(post.source))))+" ")])])],1)])})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }